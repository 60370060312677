import React, { useEffect } from 'react';
import Terminal from "../components/Terminal";
import happyBirthdayFx from '../assets/happy-birthday.mp3';
import useSound from "use-sound";

const getYear = () => {
    return new Date().getFullYear();
};

const welcomeMessage = `Greetings traveler! Welcome to my website.

Type 'help' to view a list of available commands.
`;

const bannerCondensed =
    "  _               _                                             \n" +
    " | |             | |                                            \n" +
    " | |__   ___  ___| |_ ___   ___  ___ _   _ _ __ ___   ___ _ __  \n" +
    " | '_ \\ / _ \\/ __| __/ _ \\ / __|/ _ \\ | | | '_ ` _ \\ / _ \\ '_ \\ \n" +
    " | |_) |  __/\\__ \\ ||  __/ \\__ \\  __/ |_| | | | | | |  __/ | | |\n" +
    " |_.__/ \\___||___/\\__\\___| |___/\\___|\\__, |_| |_| |_|\\___|_| |_|\n" +
    "                                      __/ |                     \n" +
    "                                     |___/                      \n" +
    "  \u00A9 " +
    getYear();

const prompt = ">";

const Secret = () => {
    return (
        <Terminal
            welcomeMessage={welcomeMessage}
            banner={bannerCondensed}
            terminalPrompt={prompt}
        />
    )
}

export default Secret;
