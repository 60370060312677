import logo from './logo.svg';
import React from 'react';
import './App.css';
import Secret from "./pages/Secret";

function App() {
  return (
    <Secret />
  );
}

export default App;
